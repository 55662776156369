import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import * as style from './cast.module.css'

const Cast = () => {
  return (
    <Layout pageTitle="Spiked">
      <Helmet>
        <meta
          name="description"
          content="Spiked film cast: Aidan Quinn, Deirdre Lovejoy, Carlos Gomez, Danay Garcia, Wendy Makkena, Sal Lopez, Walter Belenky, Lovensky Jean-Baptiste"
        />
        <title>Cast | Spiked Film</title>
        <body className="bg-img cast-bg-img" />
      </Helmet>
      <div className={style.items}>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Aidan Quinn reading a newspaper"
              src="../images/cast/aidan.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Aidan Quinn</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Deirdre Lovejoy"
              src="../images/cast/deirdre.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Deirdre Lovejoy</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Carlos Gómez"
              src="../images/cast/carlos.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Carlos Gómez</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Danay Garcia"
              src="../images/cast/danay.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Danay Garcia</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Wendy Makkena"
              src="../images/cast/wendy.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Wendy Makkena</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Sal Lopez"
              src="../images/cast/sal.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Sal Lopez</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Walter Belenky"
              src="../images/cast/walter.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Walter Belenky</span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.top}>
            <StaticImage
              className={style.responsive}
              alt="Photo of Lovensky Jean-Baptiste"
              src="../images/cast/lovensky.jpg"
            />
          </div>
          <div className={style.bottom}>
            <span className={style.name}>Lovensky Jean-Baptiste</span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Cast
